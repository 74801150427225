* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

@import url("https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Raleway:ital,wght@0,100..900;1,100..900&display=swap");

body {
  background-color: hsl(217, 28%, 15%);
  font-family: "Open Sans", sans-serif;
}

/* HEADER COMPONENT STYLES */
/* ---------------------------------------------------------------------------------- */

#header {
  height: 150px;
  display: flex;
  align-items: center;
  padding: 0 60px;
}
.header-image {
  display: flex;
  flex: 1;
}
.header-image img {
  display: flex;
}
.nav-group {
  color: hsl(0, 0%, 100%);
  font-family: "Raleway", sans-serif;
}
.nav-group-item {
  display: inline-block;
  padding: 10px;
  border: 1px solid transparent;
  cursor: pointer;
  font-size: 14px;
  font-weight: 550;
  transition: all 0.45s;
}
.nav-group-item a {
  text-decoration: none;
  color: white;
  display: inline-block;
  padding: 10px;
  border: 1px solid transparent;
  cursor: pointer;
  font-size: 14px;
  font-weight: 550;
  transition: all 0.45s;
}
.nav-group-item a:hover {
  border: 1px solid white;
  background: #1a3c70;
  color: white;
}

/* MOBILE NAVBAR STYLES */

.intro-container {
  padding-bottom: 180px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  text-align: center;
}
.intro-title {
  color: white;
  font-size: 45px;
  max-width: 570px;
  font-weight: 300;
  /* margin: 20px; */
  text-align: left;
}
.benefits-title {
  color: white;
  text-align: center;
  font-size: 45px;
  font-weight: 300;
  margin: 80px;
}
.intro-desc {
  color: white;
  font-size: 15px;
  max-width: 420px;
  color: rgb(214, 214, 214);
  line-height: 22px;
}

.intro-illustration {
  object-fit: contain;
  max-width: 500px;
  width: 100%;
}

#button.intro {
  margin-top: 25px;
}

/* BUTTON STYLES */
/* ---------------------------------------------------------------------------------- */

#button {
  cursor: pointer;
  border: none;
  outline: none;
  color: white;
  padding: 12px 65px;
  border-radius: 25px;
  background: linear-gradient(35deg, hsl(176, 68%, 64%), hsl(198, 60%, 50%));
  box-shadow: 0px 0px 4px 0.3px hsl(198, 60%, 50%);
  transition: all 0.35s;
  max-width: 200px;
  width: 100%;
}
#button:hover {
  transform: scale(1.05);
  box-shadow: 0px 0px 7px 0.1px hsl(198, 60%, 50%);
}

/* FEATURES CONTAINER STYLES */
/* ---------------------------------------------------------------------------------- */

.bg-curvy-image {
  position: absolute;
  z-index: -100;
  height: 300px;
  top: -300px;
  width: 100%;
}

.features-container {
  padding-top: 20px;
  position: relative;
  background: hsl(218, 28%, 13%);
  /*display: flex;*/
  justify-content: center;
  align-items: center;
}
.cards {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr; /* Creates 3 equal-width columns */
  margin: auto;
  min-width: 60%;
  place-items: center;
}
.card {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 20px;
  padding: 15px;
  transition: all 0.35s;
  width: fit-content;
  border: 1px solid transparent;
  cursor: pointer;
}
.card:hover {
  transform: scale(1.05);
  box-shadow: 0px 5px 15px 0.5px rgba(0, 0, 0, 0.35);
}
.card img {
  margin-bottom: 10px;
  width: 80px;
}
.card-title {
  color: rgb(238, 238, 238);
  font-size: 22px;
  line-height: 40px;
}
.card-desc {
  line-height: 22px;
  color: rgb(201, 201, 201);
  max-width: 350px;
  font-size: 14px;
}

/* PRODUCTIVITY CONTAINER STYLES */
/* ---------------------------------------------------------------------------------- */

.productivity-body {
  background: hsl(218, 28%, 13%);
  display: flex;
  padding: 30px;
}
.productivity-container {
  display: flex;
  min-width: 60%;
  margin: auto;
  align-items: center;
}
.productivity-illustration {
  object-fit: contain;
  width: 50%;
  margin-right: 20px;
}
.productivity-text-container h2 {
  color: white;
  font-weight: 300;
  max-width: 500px;
  width: 100%;
  font-size: 45px;
}
.productivity-text-container p {
  margin-top: 15px;
  max-width: 400px;
  width: 100%;
  color: rgb(214, 214, 214);
  line-height: 25px;
}
.productivity-text-container .link {
  color: hsl(176, 68%, 64%);
  width: fit-content;
  position: relative;
  cursor: pointer;
}
.productivity-text-container .link::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -5px;
  width: 100%;
  height: 2px;
  background: hsl(176, 68%, 64%);
}

/* FEEDBACKS COMPONENT STYLES*/
/* ---------------------------------------------------------------------------------- */

.feedback-container {
  position: relative;
  flex-direction: column;
  padding: 60px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: hsl(218, 28%, 13%);
  padding-bottom: 300px;
}
.feedbacks {
  grid-template-columns: 1fr 1fr 1fr;
  display: grid;
}
.feedback-card {
  background: hsl(219, 30%, 18%);
  padding: 20px 20px;
  max-width: 450px;
  margin: 20px;
  box-shadow: 0px 5px 15px 0.5px rgba(0, 0, 0, 0.35);
}
.feedback-card:nth-child(1) {
  animation: floatingFeedbacks infinite 5s;
}
.feedback-card:nth-child(2) {
  animation: floatingFeedbacks infinite 7s;
}
.feedback-card:nth-child(3) {
  animation: floatingFeedbacks infinite 9s;
}
.feedback-header {
  color: white;
  padding-top: 15px;
  line-height: 22px;
  letter-spacing: 0.6px;
  font-size: 15px;
}
.feedback-footer {
  display: flex;
  align-items: center;
  margin-top: 25px;
}
.feedback-footer img {
  object-fit: contain;
  border-radius: 50%;
  width: 40px;
}
.feedback-aboutUser {
  margin-left: 10px;
  line-height: 20px;
}
.feedback-aboutUser .user-name {
  font-weight: bold;
  font-size: 16px;
  letter-spacing: 0.7px;
  color: white;
}
.feedback-aboutUser .user-job {
  color: rgb(230, 230, 230);
  font-size: 13px;
}

.get-started-container {
  display: flex;
  position: absolute;
  flex-direction: column;
  align-items: center;
  max-width: 600px;
  width: 95%;
  color: white;
  padding: 25px;
  border-radius: 8px;
  box-shadow: 0px 5px 15px 1px rgba(0, 0, 0, 0.5);
  background: hsl(218, 28%, 13%);
  bottom: -90px;
}
.title {
  font-weight: bolder;
  font-size: 23px;
}
.description {
  line-height: 22px;
  letter-spacing: 0.8px;
  text-align: center;
  margin: 10px 0;
}
.submit-form {
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
}
.submit-form input {
  width: 70%;
  margin: 10px 20px;
  padding: 10px 30px;
  border-radius: 25px;
  outline: none;
  border: none;
}
.submit-form input::placeholder {
  color: rgba(169, 169, 169, 0.535);
}
.get-started-button {
  font-size: 0.8rem;
  padding: 10px !important;
  width: 180px;
}
.email-error-icon {
  position: absolute;
  right: 190px;
  display: none;
}
.active {
  display: block;
}
.get-started-err-msg {
  position: absolute;
  right: 190px;
  bottom: -10px;
  letter-spacing: 1px;
  font-size: 12px;
  color: hsl(0, 100%, 74%);
}

/* FOOTER COMPONENT STYLES*/
/* ---------------------------------------------------------------------------------- */
.footer-container {
  background: hsl(216, 53%, 9%);
  padding-top: 50px;
  padding-bottom: 50px;
}
.footer-information {
  padding: 0 20px;
  color: white;
  display: flex;
  font-size: 14px;
  letter-spacing: 0.7px;
}
.footer-logo {
  padding: 0 20px;
}
.information-col {
  line-height: 24px;
  width: 100%;
  display: grid;
  /* place-items: center; */
}
.information-col ul li {
  display: flex;
  padding: 5px;
  transition: all 0.35s;
  border: 1px solid transparent;
  cursor: pointer;
  margin: 5px 0;
}
.information-col ul li:hover {
  border: 1px solid white;
  background: white;
  color: hsl(218, 28%, 13%);
}
.information-row {
  margin: 20px 0;
}
.information-row img {
  margin-right: 15px;
  object-fit: contain;
}
.information-row.row-1 {
  display: flex;
}
.information-row.row-2 {
  display: flex;
}
.information-col.col-1 {
  display: flex;
  align-items: center;
  max-width: 450px;
  width: 100%;
}
.information-col.col-1 img {
  /* margin-bottom: 15px; */
  margin-right: 15px;
  object-fit: contain;
  height: 35px;
}

/* ALL KEYFRAMES */
/* ---------------------------------------------------------------------------------- */

@keyframes floatingFeedbacks {
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(5px);
  }
  0% {
    transform: translateY(0px);
  }
}

img {
  transition: all 0.5s;
}

img:hover {
  transform: scale(1.05);
}

/* MEDIA QUERIES ( 944px ) */
/* ---------------------------------------------------------------------------------- */

@media (max-width: 944px) {
  .cards {
    grid-template-columns: 1fr;
    display: grid;
    place-items: center;
  }
  .productivity-container {
    flex-direction: column;
  }
  .productivity-text-container {
    margin-top: 30px;
    display: grid;
    place-items: center;
    text-align: center;
  }
  .productivity-text-container h2 {
    font-size: 2rem;
  }
  .intro-illustration {
    width: 80vw;
  }
  .productivity-container img {
    width: 60vw;
  }
  .feedbacks {
    grid-template-columns: 1fr;
  }
  .get-started-container .title {
    font-size: 1.2rem;
  }
  .get-started-container .description {
    font-size: 0.8rem;
  }
  .footer-information {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  .information-col {
    margin: 20px 0;
  }
}

.email-error-icon {
  position: absolute;
  display: none;
  right: 190px;
  top: 15px;
}
.get-started-msg {
  position: absolute;
  right: 190px;
  bottom: -10px;
  letter-spacing: 1px;
  font-size: 12px;
}

.get-started-msg.err {
  color: hsl(0, 100%, 74%);
}
.get-started-msg.succ {
  color: hsl(154, 88%, 33%) !important;
}
.active {
  display: block;
}
/* MEDIA QUERIES ( 550px ) */
/* ---------------------------------------------------------------------------------- */

@media (max-width: 550px) {
  .email-error-icon {
    right: 5px;
    top: 15px;
  }
  .get-started-msg {
    right: 10px;
    top: -10px;
    height: fit-content;
  }

  .productivity-illustration {
    width: 90vw !important;
  }
  .intro-container {
    padding-bottom: 180px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    text-align: center;
  }
  .header-image img {
    object-fit: contain;
  }
  .intro-title {
    font-size: 1.2rem;
  }
  .intro-desc {
    font-size: 0.8rem;
  }
  #header {
    flex-direction: column;
    padding: 0 !important;
  }
  .submit-form {
    margin-top: 10px;
    flex-direction: column;
  }
  .submit-form button,
  .submit-form input {
    width: 100%;
  }
  .get-started-container .title {
    font-size: 0.9rem;
  }
  .get-started-container .description {
    font-size: 0.7rem;
  }
}

.price-body {
  height: 100%;
  display: flex;
  justify-content: space-around;
  place-items: normal;
  background: hsl(218, 28%, 13%);
  padding: 100px 0;
}
.price-container {
  box-shadow: 0px 0px 10px 0.1px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  overflow: hidden;
  width: 100%;
  font-family: sans-serif;
  margin: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.price-header {
  padding: 30px;
}
.header-title {
  font-weight: 600;
}
.header-title.one {
  font-size: 22px;
  color: hsl(0deg 0% 100%);
  margin-bottom: 20px;
}
.header-title.two {
  margin-bottom: 10px;
  color: hsl(113.27deg 65.46% 37.76%);
}
.header-description {
  line-height: 25px;
  color: hsl(0deg 0% 82.11%);
}
.price-bottom-cards {
  width: 100%;
  display: flex;
}
.price-header {
  background: hsl(209.82deg 62% 43% / 2%);
}
.price-bottom-card {
  width: 100%;
  padding: 30px;
  color: white;
  background: hsl(209.82deg 62% 43%);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.price-bottom-card.right {
  padding: 30px 60px;
  background: hsl(213.74deg 49.08% 40.3%);
}
.price-title {
  font-size: 17px;
  margin-bottom: 20px;
}
.card-price {
  font-size: 30px;
  margin-right: 10px;
}
.row-middle {
  display: flex;
  align-items: center;
}
.price-bottom-card p {
  margin-top: 15px;
  color: rgb(223, 223, 223);
}
.row-two.span {
  color: rgb(224, 224, 224);
}
.sign-button {
  background: hsl(213.74deg 49.08% 40.3%);
  border: none;
  outline: none;
  color: white;
  font-weight: 600;
  box-shadow: 0px 0px 10px 0.1px rgba(0, 0, 0, 0.4);
  font-size: 16px;
  width: 100%;
  margin-top: 40px;
  padding: 15px 0;
  border-radius: 5px;
  cursor: pointer;
}
.sign-button:hover {
  background: hsl(217, 28%, 15%);
}
.list-group {
  display: flex;
  flex-direction: column;
}
.list-group-item {
  display: inline-block;
  color: rgb(228, 228, 228);
  margin: 5px 0;
  font-weight: 400;
}
.list-group-item::before {
  content: "";
  position: absolute;
  width: 8px;
  height: 8px;
  transform: translateY(5px) translateX(-15px);
  background: rgb(7, 136, 179);
  border-radius: 5px;
}
@media (max-width: 768px) {
  .price-bottom-cards {
    flex-direction: column;
  }
}

.about-founder {
  display: flex;
  background: hsl(218, 28%, 13%);
}
.about-founder-container {
  display: flex;
  align-items: center;
  background: #1c2132;
  max-width: 1100px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin: 0px auto;
  margin-top: 50px;
  margin-bottom: 150px;
  padding: 35px;
}

.founder-photo img {
  width: 300px;
  height: 300px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 35px;
}

.founder-text {
  max-width: 600px;
}

.founder-text h2 {
  font-size: 28px;
  margin-bottom: 10px;
  color: white;
}

.founder-text p {
  font-size: 16px;
  line-height: 1.6;
  color: white;
}

@media (max-width: 768px) {
  .about-founder {
    flex-direction: column;
    text-align: center;
  }

  .founder-photo img {
    margin-right: 0;
    margin-bottom: 20px;
  }

  .founder-text h2 {
    font-size: 24px;
  }
}

.features {
  max-width: fit-content;
  margin: auto;
  display: grid;
  place-items: center;
  padding-top: 50px;
}

.features .title {
  margin-bottom: 10px;
  font-size: 1.5rem;
  color: hsl(0, 0%, 100%);
}

.features .desc {
  text-align: center;
  max-width: 700px;
  width: 100%;
  letter-spacing: 0.8px;
  color: white;
  font-size: 0.9rem;
}

.features .features-nav {
  margin-top: 50px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  width: 100%;
  padding-bottom: 0.5px;
  color: white;
  border-bottom: 1px solid rgb(233, 233, 233);
}

.features .features-nav #col {
  text-align: center;
  padding: 15px 0;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: all 0.15s
}

/*.features .features-nav #col::after {*/
/*  content: "";*/
/*  position: absolute;*/
/*  width: 100%;*/
/*  left: 0;*/
/*  bottom: 0;*/
/*  height: 2px;*/
/*  transform: translateX(-250px);*/
/*  background: hsl(0, 94%, 66%);*/
/*  transition: all 1.5s;*/
/*}*/

.features .features-nav #col.active {
  transform: scale(1.05);
  color: black;
  background: white;
  border-radius: 5px;
  font-weight: 600;
}

#section-with-illustration {
  display: flex;
  margin-top: 50px;
  margin-bottom: 150px;
  justify-content: space-evenly;
}

#section-with-illustration .left {
  color: hsl(0, 0%, 98%);
}

#section-with-illustration .left h1 {
  max-width: 450px;
  min-width: 500px;
  width: 100%;
  font-size: 50px;
  font-weight: 500;
}

#section-with-illustration .left p {
  max-width: 500px;
  width: 100%;
  color: white;
}

#section-with-illustration .right {
  display: flex;
  align-items: center;
  position: relative;
}

#section-with-illustration .right img {
  object-fit: contain;
  width: 100%;
  height: 100%;
  transform: translateX(10%);
  transition: all 0.25s;
}

/*#section-with-illustration .right::after {*/
/*  content: "";*/
/*  position: absolute;*/
/*  background: hsl(231, 69%, 60%);*/
/*  max-height: 850px;*/
/*  max-width: 750px;*/
/*  width: 60%;*/
/*  height: 35%;*/
/*  right: 0;*/
/*  transform: translateY(100px);*/
/*  z-index: -1;*/
/*  border-top-left-radius: 50%;*/
/*  border-bottom-left-radius: 50%;*/
/*}*/
#section-with-illustration.more-info {
  margin-top: 150px;
  flex-direction: row-reverse;
}

#section-with-illustration.more-info .left {
  display: flex;
  flex-direction: column;
  /*align-items: center;*/
}

#section-with-illustration.more-info .left h1 {
  max-width: 450px;
  min-width: 450px;
  width: 100%;
  font-size: 50px;
  font-weight: 500;
}

#section-with-illustration.more-info .left p {
  max-width: 500px;
  width: 100%;
  color: white;
  padding: 15px 0;
}

#section-with-illustration.more-info .right img {
  object-fit: contain;
  width: 90%;
}

#section-with-illustration.more-info .right::after {
  left: 0;
  transform: translateY(100px) rotate(180deg);
}

#section-with-illustration.tab-1.active,
#section-with-illustration.tab-2.active,
#section-with-illustration.tab-3.active {
  display: flex;
  padding-bottom: 50px;
  align-items: center;
}

#section-with-illustration.tab-1,
#section-with-illustration.tab-2,
#section-with-illustration.tab-3 {
  display: none;
}
.calendly-container {
  width: 100%;
  justify-content: center;
  background: hsl(218, 28%, 13%);
  padding: 150px;
}


@media screen and (max-width: 1248px) {
  .intro-container {
    padding: 0 40px 180px 40px;
  }

  .intro-illustration {
    max-width: 430px;
  }

  .intro-title {
    font-size: 43px;
  }
}

@media screen and (max-width: 1024px) {
  .intro-container {
    padding: 60px 20px 20px 20px;
    flex-direction: column;
    gap: 40px;
  }

  #section-with-illustration.tab-1.active,
  #section-with-illustration.tab-2.active,
  #section-with-illustration.tab-3.active {
    padding: 20px;
  }

  #section-with-illustration.more-info .right img {
    width: 75%;
  }

  #section-with-illustration.more-info .left h1 {
    min-width: auto !important;
  }

  .price-body {
    place-items: normal !important;
  }

  .price-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .sign-button {
    font-size: 11px;
  }

  .price-bottom-card {
    padding: 15px;
  }

  .price-bottom-card.right {
    padding: 20px 30px;
  }

  .calendly-container {
    padding: 0 0 100px 0;
  }

  .cards {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media screen and (max-width: 900px) {
  .price-body {
    flex-direction: column;
  }

  .price-container {
    width: 95%;
  }
}

@media screen and (max-width: 767px) {
  .bg-curvy-image {
    display: none;
  }

  .intro-title {
    font-size: 1.4rem;
    text-align: center;
  }

  .intro-title br {
    display: none;
  }

  .features-container {
    padding: 20px;
  }

  .bg-curvy-image {
    width: 95%;
    left: 0;
  }

  .benefits-title {
    margin: 40px 20px;
    font-size: 28px;
  }

  .cards {
    grid-template-columns: repeat(1, 1fr);
  }

  .card-title {
    font-size: 20px;
    line-height: 130%;
  }

  .card-desc {
    margin-top: 15px;
  }

  .features {
    padding: 20px;
  }

  .features .features-nav {
    grid-template-columns: repeat(1, 1fr);
    gap: 15px;
    padding-bottom: 20px;
  }

  #section-with-illustration.more-info {
    margin-top: 60px;
  }

  #section-with-illustration.more-info .left {
    align-items: center;
    text-align: center;
  }

  #section-with-illustration.more-info .left h1 {
    min-width: auto !important;
    font-size: 30px;
  }

  #section-with-illustration.tab-1.active,
  #section-with-illustration.tab-2.active,
  #section-with-illustration.tab-3.active {
    flex-direction: column-reverse;
    gap: 40px;
  }

  #section-with-illustration .right {
    justify-content: center;
  }

  #section-with-illustration.more-info .right img {
    width: 75%;
    transform: translateX(0px);
  }

  #section-with-illustration {
    padding: 20px;
  }

  .price-body {
    padding: 60px 20px;
    flex-direction: column;
    gap: 40px;
  }

  .price-container {
    margin: 0;
    width: 100%;
  }

  .about-founder-container {
    flex-direction: column;
  }

  .calendly-container {
    width: 95vw;
    margin: 0 auto;
    padding: 40px 0;
    overflow: hidden;
  }

  .calendly-inline-widget .calendly-mobile {
    width: 100% !important;
    min-width: auto !important;
  }
}
.calendly-inline-widget {
  height: 665px !important;
  background-color: hsl(218, 28%, 13%) !important;
}

/* VideoPlayer.css */

.video-container {
  max-width: 1200px;
  margin: 40px auto;
  padding: 20px;
  border-radius: 15px;
  margin-bottom: 100px;

  /*box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);*/
}

.video-player {
  width: 100%;
  height: auto;
  border-radius: 10px;
  outline: none;
}

.video-player::-webkit-media-controls {
  filter: drop-shadow(0 0 3px rgba(0, 0, 0, 0.5));
}

.video-player:hover {
  filter: brightness(110%);
}

.video-container iframe {
  max-width: 1200px !important;
  width: 100% !important;
}